function toPersianNum(s) {
  if (s !== undefined) {
    return s.toString().replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
  }
}
function toEnglishNum(s) {
  if (s !== undefined) {
    return s.toString().replace(/\d/g, (d) => "0123456789"[d]);
  }
}

function TypeNumber(e) {
  var code = e.keyCode || e.which;
  if (!e.ctrlKey) {
    if (code > 31 && (code < 48 || code > 57) && (code < 96 || code > 105)) {
      return false;
    }
  }
  return true;
}

function ConvertPersianToEnglish(e) {
  var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
    fixNumbers = function (str) {
      if (typeof str === "string") {
        for (var i = 0; i < 10; i++) {
          str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
      }
      return str;
    };
  return fixNumbers(e);//.replace(/\s/g, "")
}

function phoneRegex(phoneNum) {
  if (/^0?9[0-9]{9}$/.test(phoneNum) && (phoneNum.length === 11 || phoneNum.length === 10)) {
    return true;
  } else return false;
}
function phoneFormater(e, ref_num) {
  const txt = e.target.value;
  // prevent more than 12 characters, ignore the spacebar, allow the backspace
  if ((txt.length == 12 || e.which == 32) & (e.which !== 8)) e.preventDefault();
  // add spaces after 3 & 7 characters, allow the backspace
  if ((txt.length == 3 || txt.length == 7) && e.which !== 8) ref_num.value = ref_num.value + " ";
}

function validateMelliCode(code) {
  code = ConvertPersianToEnglish(code).replace(/\s/g, "");
  console.log(code);
  if (!/^\d{10}$/.test(code)) return false;

  const check = parseInt(code[9], 10);
  const sum = [...code.slice(0, 9)].reduce((acc, val, idx) => acc + parseInt(val, 10) * (10 - idx), 0);

  return (sum % 11 < 2 && check === sum % 11) || (sum % 11 >= 2 && check + (sum % 11) === 11);
}
function validateHomeTell(phoneNumber) {
  if (/^0[0-8]{2,}[0-9]{7,}$/.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
}
function isNum(inp){
  const regexInt = /^\d+$/;
  if(inp === ""){
    return false;
  }else {
    if (regexInt.test(inp.replaceAll(",","").replaceAll(".",""))) {
      return true;
    } else {
      return false;
    }
  }
}
function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email)
}
function checkFillables(obj, OptionalItems: []) {
  for (const key in obj.value) {
    if (!obj.value[key] && !OptionalItems.includes(key)) {
      return false;
    }
  }
  return true;
}
function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function isValidWalletAddress(walletAddress, network) {
  if(validateEmail(walletAddress) || phoneRegex(walletAddress)){
    return false;
  }else{
    const networkFormats = {
      "Tron(TRC20)": /^(T)[a-zA-Z0-9]{33}$/, // TRON
      "Ethereum(ERC20)": /^0x[a-fA-F0-9]{40}$/, // Ethereum
      "BNB Smart Chain(BEP20)": /^0x[a-fA-F0-9]{40}$/, // Binance Smart Chain
      "BNB Beacon Chain(BEP2)": /^[A-Za-z0-9]{42}$/, // Binance Chain (BEP2)
      "Bitcoin(BTC)": /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/, // Bitcoin
      "Dogecoin(DOGE)": /^([A-HJ-NP-Za-km-z1-9]{33}|[A-HJ-NP-Za-km-z1-9]{34})$/, // Dogecoin
      "Monero(XMR)": /4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}$/g, // Monero (XMR)
      "Ripple(XRP)": /^r[0-9a-zA-Z]{24,34}$/, // Ripple (XRP)
      "Litecoin(LTC)": /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/, // Litecoin (LTC)
      "Solana(SOL)": /^[1-9A-HJ-NP-Za-km-z]{44}$/, // Solana (SOL)
      ZED: /^t1[0-9A-Za-z]{34}$/, // Zcash (ZEC)
      CHZ: /^0x[a-fA-F0-9]{40}$/, // Chiliz (CHZ)
      DASH: /^X[1-9A-HJ-NP-Za-km-z]{33}$/, // Dash
      NEO3: /^A[0-9a-zA-Z]{33}$/, // NEO
      "Bitcoin Cash(BCH)": /^[13][a-km-zA-HJ-NP-Z1-9]{25,42}$/, // Bitcoincash (BCH)
      "Fantom(FTM)": /^0x[a-fA-F0-9]{40}$/, // Fantom (FTM)
      "Polygon(MATIC)": /^0x[a-fA-F0-9]{40}$/, // Polygon (Matic)
      "Polkadot(DOT)": /^1[0-9A-Za-z]{1,49}$/, // Polkadot (DOT)
      "Cosmos(ATOM)": /^cosmos1[a-zA-HJ-NP-Z0-9]{38}$/,
      "Algorand(ALGO)": /^[A-Z2-7]{58}$/,
      'Toncoin(TON)': /^(EQ|UQ)[a-zA-Z0-9_-]{46}$/
    };

    if (networkFormats.hasOwnProperty(network)) {
      const regex = networkFormats[network];
      return regex.test(walletAddress);
    } else {
      return true;
    }
  }
}
function summarizeAddress(address, maxLength = 30) {
  const ellipsis = "..."; // Ellipsis to indicate truncation
  if (address.length <= maxLength) {
    return address; // Return the original address if it's already within the desired length
  }

  const prefixLength = Math.floor((maxLength - ellipsis.length) / 2);
  const suffixLength = maxLength - ellipsis.length - prefixLength;

  const prefix = address.substring(0, prefixLength);
  const suffix = address.substring(address.length - suffixLength);

  return prefix + ellipsis + suffix;
}
function isBase64(str) {
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}
function isRepetitivePattern(value) {
  // Regular expression to match repetitive patterns like 0000000000, 1111111111, etc.
  const repetitivePattern = /^(.)\1{9}$/;
  return repetitivePattern.test(value);
}

export default (useInputValidation) => ({
  phoneRegex,
  ConvertPersianToEnglish,
  TypeNumber,
  toPersianNum,
  phoneFormater,
  validateMelliCode,
  toEnglishNum,
  validateHomeTell,
  validateEmail,
  checkFillables,
  numberWithCommas,
  isValidWalletAddress,
  summarizeAddress,
  isBase64,
  isNum,
  isRepetitivePattern
});
