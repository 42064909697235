<template>
  <div>
    <div class="d-flex p-2 align-items-center justify-content-between pwabox" v-if="showModal">
      <div>
        {{ $t('installPwa') }}
      </div>
      <button class="btn btn-primary" style="padding-top: 12px;" @click="showConfirmVPN = true">
        {{ $t('installPwaBtn') }}
      </button>
    </div>
    <div v-if="showModalIOS && $route.name !== 'wallet-callback'" class="iphoneInstallQuide">
      <div class="d-flex">
        <img src="@/assets/images/mbiticon.png" width="75"/>
        <div class="d-flex p-2 align-items-center justify-content-between">
          {{ $t('iphoneSetupGuide') }}
        </div>
        <div class="d-flex p-2 align-items-center justify-content-between">
          {{ $t('iphoneSetupGuideBrowser') }}
        </div>
      </div>
      <div>
        <div>
          <div>
            <img src="@/assets/images/svg/ios_share.svg" class="roundimage" />
          </div>
          <div>
            <p>{{$t('iphoneSetupP1')}}</p>
            <p>{{$t('iphoneSetupP2')}}</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/images/svg/ios_addpwa.svg" class="roundimage" />
          </div>
          <div>
            <p>{{$t('iphoneSetupP3')}}</p>
            <p>{{$t('iphoneSetupP4')}}</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/images/mbiticon.png" class="roundimageIcon"/>
          </div>
          <div>
            <p>{{$t('iphoneSetupP5')}}</p>
            <p>{{$t('iphoneSetupP6')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="module" v-if="showConfirmVPN">
      <div class="backDrop"></div>
      <div class="p-3 module-content text-center">
        <h6 class="lg-mainColor-text">{{ $t("installPwa") }}</h6>
        <p class="md-text fw-bold">
          {{ $t("vpnDescription") }}
        </p>
        <div class="d-flex" style="justify-content: space-evenly;">
          <a class="btn btn-primary confirm mt-4" @click="showPrompt">
            {{ $t("vpnIsOn") }}
          </a>
          <a class="btn btn-danger mt-4 mx-2" @click="showConfirmVPN=false;">
            {{ $t("optOut") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "installPwa",
  setup(){
    const showModal = ref(false);
    const showConfirmVPN = ref(false);
    const showModalIOS = ref(false);
    onMounted(()=>{
      window.addEventListener('beforeinstallprompt', (event) => {
        window.beforeInstallPromptEvent = event;
        showModal.value = true;//android
      });
      var isiPhone = /iPhone/i.test(navigator.userAgent);
      var isSafariOrChrome = /Safari|CriOS/i.test(navigator.userAgent);
      var isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

      if (isiPhone && isSafariOrChrome && !isInStandaloneMode) {
        showModalIOS.value = true;
      } else {
        showModalIOS.value = false;
      }
    })
    const showPrompt = ()=>{
      showConfirmVPN.value = false;
      window.beforeInstallPromptEvent.prompt();
    }
    return {
      showModal,
      showPrompt,
      showConfirmVPN,
      showModalIOS
    }
  }
}
</script>

<style scoped>
.pwabox{
  position: absolute;
  top: 0;
  width: 100%;
  background: black;
  z-index: 9999;
}
.iphoneInstallQuide{
  position: absolute;
  width: 100%;
  height: 100vh;
  color:var(--pen-color);
  background-color: var(--background-color);
  z-index: 99999;
  padding-top: 25%;
  padding-left: 25px;
  padding-right: 25px;

}
.iphoneInstallQuide > div:first-child{
  margin:0 auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  font-size: 16px;
}
.iphoneInstallQuide > div:last-child{
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px;
  margin-top: 50px;
}
.iphoneInstallQuide > div:last-child > div{
  display: flex;
  align-items: center;
  gap: 10px;
}
.roundimage{
  padding: 12px;
  border-radius: 50%;
  background-color: rgba(63, 104, 253, 0.20);
}
.roundimageIcon{
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.iphoneInstallQuide div > p:first-child{
  font-weight: bold;
}
.iphoneInstallQuide div > p:last-child{
  font-size: 14px;
  margin-top: 0;
  opacity: 0.8;
}
body {
  /* Other styles above... */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
</style>